<template>
  <div class="gift-bag">
    <div class="header">
      <divider-title text="尊享礼券" />
      <span class="label">*优惠券使用最终解释权归伊莉丝钻石所有</span>
    </div>
    <div class="container">
      <div class="list">
        <div class="filter">
          <div
            :class="{ 'filter-item': true, actived: searchData.sType === item.id }"
            v-for="item in statusList"
            :key="item.id"
            @click="filterClick(item.id)"
          >
            <span>{{ item.label }}</span>
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
        <div class="giftbag-container">
          <giftbag-item
            class="giftbag-item"
            v-for="(item, index) in giftbagList"
            :key="index"
            :info="item"
          />
           <giftbag-item
            class="giftbag-item"
            style="height:0;overflow:hidden;border:none"
          />
           <giftbag-item
            class="giftbag-item"
            style="height:0;overflow:hidden;border:none"
          />
        </div>
        <div class="pagenation">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            @current-change="pageChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dividerTitle from "@/components/dividerTitle";
import giftbagItem from "./components/giftbagItem.vue";
import { GET_COUPON } from "../../service/coupon";

export default {
  name: "Account",
  components: { dividerTitle, giftbagItem },
  data() {
    return {
      giftbagList: [],
      statusList: [
        { id: -1, label: "所有优惠券" },
        { id: 0, label: "可使用优惠券" },
        { id: 1, label: "已使用优惠券" },
        { id: 2, label: "已可期优惠券" }
      ],
      searchData: {
        start: 0,
        size: 6,
        sType: -1
      },
      total: 0
    };
  },
  computed: {},
  mounted() {
    this.getCoupon();
  },
  methods: {
    getCoupon() {
      GET_COUPON({ ctype: 0, ...this.searchData }).then(res => {
        this.total = res.attachment.total;
        this.giftbagList = res.attachment.datas;
      });
    },
    filterClick(id) {
      this.searchData.sType = id;
      this.getCoupon();
    },
    pageChange(val) {
      this.searchData.start = (val - 1) * this.searchData.size;
      this.getCoupon();
    }
  }
};
</script>
<style lang="less" scoped>
.gift-bag {
  background: #f5f5f5;
  box-shadow: 0px 4px 5px 2px #d6d6d6;
  .header {
    padding-top: 28px;
    height: 96px;
    background: #fff;
    position: relative;
    .label {
      position: absolute;
      right: 20px;
      bottom: 14px;
    }
  }
  .container {
    padding: 20px 52px 40px;
  }
  .filter {
    padding: 0 38px;
    line-height: 40px;
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .filter-item {
      color: #666;
    }
    .filter-item.actived {
      color: #181818;
    }
  }
  .list {
    padding: 42px 40px 0;
    background: #fff;
    box-shadow: 0px 4px 5px 2px #d6d6d6;
    .giftbag-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .giftbag-item {
        margin-bottom: 30px;
      }
    }
  }
  .pagenation {
    background: #fff;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
