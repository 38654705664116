<template>
  <div :class="['giftbag-item', statusMap[info.status]]">
    <div class="top">
      <div class="num">
        <span class="money" v-if="info.type === 1">
          <i>￥</i>
          {{ info.amount/100 }}
        </span>
        <span class="discount" v-else-if="info.type === 0">
          {{ info.amount }}
          <i>折</i>
        </span>
      </div>
      <div class="desc">满 {{ info.conditions/1000 }} 元可使用</div>
      <div class="date">{{ info.etStr }}</div>
    </div>
    <div class="bottom">
      <div class="bottom-item">
        <span class="name">限品类：</span>
        <span class="value" :title="info.c">{{ info.c }}</span>
      </div>
      <div class="bottom-item">
        <span class="name">票券号：</span>
        <span class="value" :title="info.code">{{ info.code }}</span>
      </div>
      <div class="bottom-item">
        <span class="name">可叠加：</span>
        <span class="value">{{ info.overlay===0? "不":''}}可叠加</span>
      </div>
      <div class="btn" v-if="info.status == 0" @click="uplist()">立即使用</div>
    </div>
    <div :class="['tag', statusMap[info.status]]" v-if="info.status !== 1"></div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "GiftbagItem",
  props: {
    info: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      statusMap: {
        0: "unused",
        1: "used",
        2: "over"
      }
    };
  },
  methods: {
    operate(type) {
      this.$emit("operate", type, this.info);
    },
    uplist(){
      this.$router.push({
        path:'/production.html'
      })
    }
  },
  mounted(){
    console.log(this.info)
  }
};
</script>
<style lang="less" scoped>
.giftbag-item {
  width: 254px;
  height: 440px;
  border: 1px solid #ebebeb; /*no*/
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  .top {
    color: #fff;
    text-align: center;
    height: 184px;
    .num {
      font-size: 38px;
      line-height: 38px;
      // font-weight: bold;
      padding: 30px 0 22px;
      i {
        font-size: 27px;
        font-style: normal;
        // font-weight: normal;
      }
    }
    .desc {
      font-size: 14px;
      line-height: 1;
      padding-bottom: 14px;
    }
    .date {
      font-size: 14px;
      line-height: 60px;
    }
  }
  .bottom {
    padding: 17px 20px 0;
    line-height: 34px;
    font-size: 14px;
    .bottom-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .name {
      color: #666;
    }
    .value {
      color: #999;
    }
    .btn {
      width: 124px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #921e21;
      border: 1px solid #921e21; /*no*/
      border-radius: 20px;
      margin: 40px auto;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .tag {
    position: absolute;
    width: 98px;
    height: 98px;
    bottom: 0;
    right: 0;
    background-size: 100% 100%;
  }
  .tag.used {
    background-image: url("../../../assets/images/coupon-text1.png");
  }
  .tag.over {
    background-image: url("../../../assets/images/coupon-text2.png");
  }
}
.giftbag-item.unused {
  background-image: url("../../../assets/images/coupon1.png");
  background-size: 100% auto;
}
.giftbag-item.used {
  background-image: url("../../../assets/images/coupon2.png");
  background-size: 100% auto;
}
.giftbag-item.over {
  background-image: url("../../../assets/images/coupon3.png");
  background-size: 100% auto;
}
</style>
